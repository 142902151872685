import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { format } from "date-fns";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { Dropdown } from "react-bootstrap";
import CubeOutlineIcon from "mdi-react/CubeOutlineIcon";
import Datetime from "react-datetime";
import { Popover } from "react-tiny-popover";
import DatePickerCustomInput from "../utils/DatePickerCustomInput";
import { convertMomentDate } from "../../utils/helpers";
import ViewIssueNumberModal from "../Inventory/ViewIssueSerialNumber";

const ReceiveWarehouseTable = (props) => {
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState(0);
  const [active, setActive] = useState(null);

  const [indexValue, setIndexValue] = useState(0);
  const [inputBatch, setInputBatch] = useState("");
  const [inputDate, setInputDate] = useState(new Date());
  const [datePopoverOpened, setDatePopoverOpened] = useState(false);
  const [openSerialNumbers, setOpenSerialNumbers] = useState(false);
  const [selectedSerialItems, setSelectedSerialItems] = useState({});
  const [serialNumbers, setSerialNumbers] = useState([]);

  const wareshouseLocal = JSON.parse(
    window.localStorage.getItem("warehouseName")
  );

  const update = (e) => {
    // if (e.key === "Enter") {
    if (parseInt(inputValue) < 1)
      return toast.error(`Quantity can not be less than one`);
    // if (parseInt(inputValue) > parseInt(props.el.Quantity))
    //   return toast.error(`Input a lower quantity`);
    setShowInput(false);

    props.updateQuantity(inputValue, active);
    // }
  };

  const batchUpdate = (e) => {
    props.updateBatch(inputBatch, active);
  };

  useEffect(() => {
    setInputValue(props.el.Quantity);
  }, [props.el.Quantity]);

  useEffect(() => {
    setActive((prev) =>
      props?.selectedItems?.find(
        (it) =>
          it?.Bar_Code === props?.el?.Bar_Code &&
          it?.Trans_ID === props.el.Trans_ID
      )
    );
  }, [props.el.Bar_Code, props.el.Trans_ID, props.selectedItems]);

  return (
    <>
      <tr>
        <td>
          {props?.el?.serialNumbers && (
            <Dropdown>
              <Dropdown.Toggle
                variant=""
                className="bg-white border-0"
                bsPrefix="print more"
              >
                <DotsVerticalIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu
                popperConfig={{
                  strategy: "fixed",
                }}
                renderOnMount
                className="dropdown-with-icons"
              >
                <Dropdown.Item
                  as="button"
                  onClick={() => {
                    setSelectedSerialItems(props?.el);
                    setSerialNumbers(JSON.parse(props?.el?.serialNumbers));
                    setOpenSerialNumbers(true);
                    setIndexValue(props?.index);
                  }}
                >
                  <CubeOutlineIcon className="text-light" />
                  Serial Numbers
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </td>
        <td>
          <input
            className="form-check-input"
            type="checkbox"
            onChange={() => props.onChangeSelected(props.el)}
            checked={
              active && props.el.Status !== "Rejected" && props.el.Quantity > 0
            }
            id={props.index}
            disabled={props.el.Status === "Rejected"}
          />

          <label className="form-check-label px-3" htmlFor={props.index}>
            {props.el.Bar_Code}
          </label>
        </td>
        <td onClick={() => setShowInput(false)}>{props.el.Item_Name}</td>
        <td
          onClick={() =>
            !props?.el?.serialNumbers &&
            setShowInput((prev) => (active ? true : false))
          }
        >
          {showInput ? (
            <input
              type="number"
              className="inputTable form-control border-0 px-1"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onBlur={(e) => {
                update(e, props.el.Bar_Code, props.el.Trans_ID);
              }}
              // onKeyDown={(e) => update(e, props.el.Bar_Code, props.el.Trans_ID)}
              disabled={props.selectedItems.length < 1 || !active}
              max={parseInt(props.el.Quantity)}
            />
          ) : (
            inputValue
          )}
        </td>
        {wareshouseLocal?.cater !== "Assets" && (
          <>
            <td onClick={() => setShowInput((prev) => (active ? true : false))}>
              <input
                type="text"
                className="form-control border-0 px-1"
                value={inputBatch}
                onChange={(e) => setInputBatch(e.target.value)}
                // onKeyDown={(e) =>
                //   batchUpdate(e, props.el.Bar_Code, props.el.Trans_ID)
                // }
                onBlur={(e) => {
                  batchUpdate(e, props.el.Bar_Code, props.el.Trans_ID);
                }}
                // disabled={props.selectedItems.length < 1 || !active}
              />
            </td>

            <td>
              <Popover
                reposition={false}
                isOpen={datePopoverOpened}
                onClickOutside={() => setDatePopoverOpened(false)}
                align="end"
                padding={10}
                content={() => (
                  <Datetime
                    dateFormat="MM DD, YYYY"
                    className="date-picker-2"
                    timeFormat={false}
                    closeOnSelect={true}
                    closeOnClickOutside={true}
                    name="supplyDate"
                    inputProps={{
                      className: `date-input form-control`,
                      placeholder: "Select date",
                      readOnly: true,
                    }}
                    input={false}
                    value={inputDate}
                    onChange={(date) => {
                      props.updateDate(date, active);
                      setInputDate(date);
                      setDatePopoverOpened(false);
                    }}
                  />
                )}
              >
                <DatePickerCustomInput
                  onClick={() => setDatePopoverOpened(!datePopoverOpened)}
                  value={convertMomentDate(inputDate)}
                />
              </Popover>
            </td>
          </>
        )}

        <td onClick={() => setShowInput(false)}>
          {props.el?.WH_Pending_details
            ? props.el?.WH_Pending_details?.W_name
            : props.el?.plant_Pending_details?.Plant_Name
            ? props.el?.plant_Pending_details?.Plant_Name
            : props.el?.Vendor}
        </td>
        <td>{format(new Date(props?.el?.Date_Log), "yyyy-MM-dd")}</td>
        <td>
          {
            <div
              className={`${
                props?.el?.Status === "Rejected"
                  ? "view_status_reject"
                  : props?.el?.Status === "Partly Received"
                  ? "view_status_partly"
                  : props?.el?.Status === "Received All"
                  ? "view_status_all"
                  : props?.el?.Status === "Retured"
                  ? "view_status_returned"
                  : props?.el?.Status === null
                  ? "view_status_pending"
                  : ""
              }`}
            >
              {props?.el?.Status !== null ? props?.el?.Status : "Pending"}
            </div>
          }
        </td>
      </tr>

      {openSerialNumbers && (
        <ViewIssueNumberModal
          show={openSerialNumbers}
          selectedItem={selectedSerialItems}
          setShowBatchesModal={setOpenSerialNumbers}
          serialNumbers={serialNumbers}
          updateQuantity={props.updateQuantity}
          setInputValue={setInputValue}
          updateSerial={props.updateSerial}
          handleInputChange={props.handleInputChange}
          indexValue={indexValue}
        />
      )}
    </>
  );
};

export default ReceiveWarehouseTable;
